<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
window.addEventListener("load", function () {
  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 300);
});
</script>

<style>
@keyframes hidePreloader {
  0% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 0;
    height: 0;
  }
}

body > div.preloader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 1071;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

body:not(.loaded) > div.preloader {
  opacity: 1;
}

body:not(.loaded) {
  overflow: hidden;
}

body.loaded > div.preloader {
  animation: hidePreloader 0.5s linear 0.5s forwards;
}
</style>
